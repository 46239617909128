import React, { FC } from 'react';
import { format } from 'date-fns';
import { memberInfoTimesheetsSelector } from 'features/timesheets/timesheetsSlice';
import { useSelector } from 'react-redux';
import styles from './CalendarTimeOffView.module.scss';
import { ITimeOffRequestListItem } from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import { dateToTimeZone } from 'helpers/dateToTimeZone';

interface CalendarTimeOffViewProps {
  timeOff: ITimeOffRequestListItem;
}

const CalendarTimeOffView: FC<CalendarTimeOffViewProps> = ({
  timeOff,
}) => {
  const memberInfo = useSelector(memberInfoTimesheetsSelector); //А таймшиты точно проверяются по пользователю
  const { name: memberName } = memberInfo;
  const {
    allDay,
    policy,
    startTime,
    endTime,
    timeRequested,
    reason
  } = timeOff;

  return (
    <div className={styles.wrapper}>
      <span className="input-label">Member</span>
      <div>{memberName}</div>

      <span className="input-label">Policy name</span>
      <div>{policy.name}</div>

      <span className="input-label">Total time span</span>
      <div>
        {format(
          dateToTimeZone(startTime, "UTC"),
          allDay ? 'EEE, MMM dd yyyy' : 'EEE, MMM dd yyyy, hh:mm aaa'
        )}{' '}
        -{' '}
        {format(
          dateToTimeZone(endTime, "UTC"),
          allDay ? 'EEE, MMM dd yyyy' : 'EEE, MMM dd yyyy, hh:mm aaa'
        )}
        <br />
      </div>

      <span className="input-label">Hours requested</span>
      <div>{timeRequested}</div>

      {reason && (
        <>
          <span className="input-label">Comments</span>
          <div>{reason}</div>
        </>
      )}
    </div>
  );
};

export default CalendarTimeOffView;
