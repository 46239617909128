import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useAppDispatch } from 'app/store';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import Modal from 'components/shared/Modal';
import { timeOffRequestInfoSelector } from 'features/timeOffRequestInfo/timeOffRequeststInfoSlice';
import {
  ITimeOffRequestListItem,
  ITimeOffRequestsActionsParams,
} from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import { getTimeOffRequestInfo } from 'features/timeOffRequestInfo/timeOffRequestInfoActions';
import {
  getTimeOffRequestsListInfiniteScroll,
  removeTimeOffRequest,
} from 'features/timeOffRequestsList/timeOffRequestsListActions';
import RequestsActionsList from '../RequestsActionsList';
import ViewTimeOffRequest from '../ViewTimeOffRequest';
import {
  filterTimeOffRequestsSelector,
  isLoadingTimeOffRequestsSelector,
  itemsTotalCountTimeOffRequestsSelector,
  timeOffRequestsListSelector,
} from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import PulseLoader from 'react-spinners/PulseLoader';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';
import { dateToTimeZone } from 'helpers/dateToTimeZone';

const requestsTableColumns = [
  { name: 'Member' },
  { name: 'Policy' },
  { name: 'Period' },
  { name: 'Hours requested' },
  { name: 'Status' },
  { name: 'Requested on' },
  { name: 'Actions' },
];

const RequestsTable: FC = () => {
  const timeOffRequestsPageFilter = useSelector(filterTimeOffRequestsSelector);
  const isRequestsLoading = useSelector(isLoadingTimeOffRequestsSelector);
  const infiniteListItems = useSelector(timeOffRequestsListSelector);
  const request = useSelector(timeOffRequestInfoSelector);
  const requestsTotalCount = useSelector(
    itemsTotalCountTimeOffRequestsSelector
  );
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const tabsPathFilter = pathname.replace('/time-off/requests', '');
  const [requestParamsForDelete, setRequestParamsForDelete] =
    useState<ITimeOffRequestsActionsParams | null>(null);
  const [confirmRemoveRequest, setConfirmRemoveRequest] = useState(false);
  const [requestViewModalOpen, setRequestViewModalOpen] = useState(false);
  const onRowClick = (id: number) => {
    setRequestViewModalOpen(true);
    dispatch(getTimeOffRequestInfo(id));
  };
  const handleRemove = (params: ITimeOffRequestsActionsParams) => {
    setRequestParamsForDelete(params);
    setConfirmRemoveRequest(true);
  };
  const hasNextPage = infiniteListItems.length < requestsTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getTimeOffRequestsListInfiniteScroll({
        ...timeOffRequestsPageFilter,
        path: tabsPathFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isRequestsLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const TimeOffRequestsTableItem: FC<
    ListChildComponentProps<ITimeOffRequestListItem[]>
  > = (props) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.member.name}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.policy.name}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span
              className={`${styles.fBodyCellContent} ${styles.datesContent}`}
            >
              {format(dateToTimeZone(content.startTime, "UTC"), 'MMM dd yyyy')}
              <br />
              -
              <br />
              {format(dateToTimeZone(content.endDate, "UTC"), 'MMM dd yyyy')}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.timeRequested}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell} ${styles.requestsTableStatusCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.status}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => onRowClick(content.id)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {/* {format(dateToTimeZone(content.requestedOn, content.timeZone.name), 'MMM dd yyyy')} */}
              {/* local device time zone used, member (app user) time zone should be used insted */}
              {format(new Date(content.requestedOn), 'MMM dd yyyy')} 
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <RequestsActionsList
                requestId={content.id}
                status={content.status}
                openViewModal={onRowClick}
                handleRemove={handleRemove}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.fTable} ${styles.requestTable}`}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {requestsTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell} ${
                col.name === 'Status' ? styles.requestsTableStatusCell : ''
              }`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {TimeOffRequestsTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {request && (
        <Modal
          title="Request time off"
          open={requestViewModalOpen}
          onClose={() => setRequestViewModalOpen(false)}
        >
          <ViewTimeOffRequest
            closeModal={() => setRequestViewModalOpen(false)}
            request={request}
            requestId={request.id}
          />
        </Modal>
      )}
      {requestParamsForDelete && (
        <ConfirmDialog
          open={confirmRemoveRequest}
          title="Confirm"
          description="Delete this request? You will not be able to undo the changes."
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmRemoveRequest(false)}
          onAccept={() => {
            dispatch(removeTimeOffRequest(requestParamsForDelete));
          }}
        />
      )}
    </>
  );
};

export default RequestsTable;
