import React, { FC, useMemo } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomFormikDatePicker from 'components/shared/forms/datePickers/CustomFormikDatePicker';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import FormikTextArea from 'components/shared/forms/textAreas/FormikTextArea';
import Button from 'components/shared/Button';
import { MaximumCommentLengthValidationError } from 'constants/formsConstants';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { IRequestingTimeOffData } from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import { IUser } from 'features/user/userTypes';
import { hoursColonMinutesFormat } from 'helpers/regularExpressions';
import { setTimeInDate } from 'helpers/setTimeInDate';
import DependentStartTimePicker from './DependentStartTimePicker';
import DependentEndTimePicker from './DependentEndTimePicker';
import DependentTimeZoneField from './DependentTimeZoneField';
import DependentPolicyPicker from './DependentPolicyPicker';
import DependentTimeRequestedField from './DependentTimeRequestedField';
import styles from './RequestTimeOffForm.module.scss';

interface RequestTimeOffPolicyFormProps {
  handleCLose: () => void;
  handleSubmit: (data: IRequestingTimeOffData) => void;
  userInfo: IUser;
}

export interface IRequestTimeOffFormValues {
  memberForRequest: SelectOption;
  policy: SelectOption | null;
  startDate: string | Date;
  startTime: string | Date;
  endDate: string | Date;
  endTime: string | Date;
  allDay: boolean;
  timeRequested: string;
  reason: string;
  // selectedMemberTimeZoneName: string;
}

export const MaximumHoursForTimeOffRequest = 100000;

const RequestTimeOffForm: FC<RequestTimeOffPolicyFormProps> = ({
  handleCLose,
  handleSubmit,
  userInfo,
}) => {
  const members = useSelector(membersFilterListSelector);
  const initialValues: IRequestTimeOffFormValues = {
    memberForRequest: {
      label: `${userInfo?.firstName} ${userInfo?.lastName}`,
      value: String(userInfo?.id),
    },
    policy: null,
    startDate: new Date(),
    startTime: String(setTimeInDate(new Date(), 9, 0)),
    endDate: String(new Date()),
    endTime: String(setTimeInDate(new Date(), 17, 0)),
    // allDay: false,
    allDay: true,
    timeRequested: '08:00',
    reason: '',
  };
  const validationSchema = Yup.object().shape({
    memberForRequest: Yup.object().shape({
      label: Yup.string().required('Member is required'),
      value: Yup.string(),
    }),
    policy: Yup.object()
      .shape({
        label: Yup.string().required('Policy is required'),
        value: Yup.string().required('Policy is required'),
      })
      .required('Policy is required')
      .nullable(),
    startDate: Yup.date()
      .typeError('Start date is required')
      .required('Start date is required'),
    endDate: Yup.date()
      .typeError('End date is required')
      .required('End date is required')
      .when('startDate', (startDate) => {
        return Yup.date()
          .min(startDate, 'End date must be after Start Date')
          .typeError('End date is required');
      }),
    startTime: Yup.date()
      .typeError('Start time is required')
      .required('Start time is required'),
    endTime: Yup.date()
      .typeError('End time is required')
      .required('End time is required')
      .when('startTime', (startTime) => {
        return Yup.date().when('allDay', {
          is: false,
          then: Yup.date().min(
            setTimeInDate(
              new Date(startTime),
              new Date(startTime).getHours(),
              new Date(startTime).getMinutes() + 1
            ),
            'Invalid time'
          ),
        });
      }),
    timeRequested: Yup.string()
      .matches(hoursColonMinutesFormat, 'HH:MM time format must be specifying')
      .test('maxHours', `Maximum time exceeded (99999:59)`, (value) => {
        if (!value) return true;
        const hours = value.split(':')[0];
        return Number(hours) < MaximumHoursForTimeOffRequest;
      }),
    reason: Yup.string()
      .max(
        MaximumCommentLengthValidationError,
        `Maximum length ${MaximumCommentLengthValidationError} characters`
      )
      .required('Please add a comment'),
  });
  const membersOptions = useMemo(() => {
    return members.map((member) => ({
      label: member.name,
      value: String(member.id),
    }));
  }, [members]);

  return (
    <>
      <div className={styles.wrapper}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const formattedStart = zonedTimeToUtc(
              values.allDay
                ? setTimeInDate(values.startDate, 0, 0)
                : new Date(values.startTime),
              "UTC"
            ).toISOString();

            const formattedEnd = zonedTimeToUtc(
              values.allDay
                ? setTimeInDate(values.endDate, 23, 59)
                : new Date(values.endTime),
              "UTC"
            ).toISOString();

            handleSubmit({
              memberId: Number(values.memberForRequest.value),
              policyId: Number(values.policy?.value),
              timeRequested: values.timeRequested,
              start: formattedStart,
              end: formattedEnd,
              // allDay: values.allDay,
              allDay: true,
              reason: values.reason,
            });
            setSubmitting(false);
            resetForm();
            handleCLose();
          }}
        >
          {(formik) => (
            <Form>
              <CustomFormikSelect
                label={'Member*'}
                name="memberForRequest"
                options={membersOptions}
              />

              <DependentPolicyPicker label="Policy*" name="policy" />

              <div className={styles.datesFlex}>
                <CustomFormikDatePicker
                  label="Start date"
                  name="startDate"
                  onCalendarClose={() => {
                    //change endDate if startDate became later then endDate
                    if (formik.values.allDay) {
                      const startValue = new Date(formik.values.startDate);
                      const endValue = new Date(formik.values.endDate);
                      if (startValue.getTime() > endValue.getTime()) {
                        formik.setFieldValue(
                          'endDate',
                          formik.values.startDate
                        );
                      }
                    }
                  }}
                />

                {!formik.values.allDay && (
                  <DependentStartTimePicker label="Time" name="startTime" />
                )}
              </div>
              <div className={styles.datesFlex}>
                <CustomFormikDatePicker
                  label="End date"
                  name="endDate"
                  minDate={(() => {
                    return new Date(formik.values.startDate);
                  })()}
                />
                {!formik.values.allDay && (
                  <DependentEndTimePicker label="Time" name="endTime" />
                )}
              </div>

              <DependentTimeZoneField />

              {/* <CustomFormikCheckbox label="All day" name="allDay" /> */}
              <div className={styles.hoursAmountWrapper}>
                <DependentTimeRequestedField
                  label={'Hours'}
                  name="timeRequested"
                  placeholder={'HH:MM'}
                />
              </div>
              <FormikTextArea label="Comments*" name="reason" />
              <div className={styles.controlWrapper}>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    handleCLose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  preloader={formik.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RequestTimeOffForm;
