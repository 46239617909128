import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { RootState } from 'app/store';
import {
  getMemberTimeEntries,
} from '../timeEntries/timeEntriesActions';
import {
  CalendarViews,
  EventTypes,
  FullCalendarEvent,
  ICurrentCalendarParams,
  CalendarInfoSliceInitialState
} from './calendarInfoTypes';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { sumTimeRanges } from 'helpers/sumTimeRanges';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import { holidaysListSelector } from 'features/holidaysList/holidaysListSlice';
import { ITimeEntryData } from 'features/timeEntries/timeEntriesTypes';
import { timeEntriesSelector, selectedTimeEntryIdSelector } from 'features/timeEntries/timeEntriesSlice';
import { timeOffRequestsListSelector } from 'features/timeOffRequestsList/timeOffRequestsListSlice';

const initialState: CalendarInfoSliceInitialState = {
  totalTimeLogged: '00h 00m',
  initialCalendarView: 'dayGridMonth',
  initialCalendarDate: new Date(),
  currentCalendarParams: {} as ICurrentCalendarParams,
  isLoading: false,
  errorMessage: '',
};

export const calendarInfoSlice = createSlice({
  name: '@@calendarInfo',
  initialState,
  reducers: {
    setInitialCalendarView: (
      state,
      action: PayloadAction<keyof typeof CalendarViews>
    ) => {
      state.initialCalendarView = action.payload;
    },
    setInitialCalendarDate: (
      state,
      action: PayloadAction<Date>
    ) => {
      state.initialCalendarDate = action.payload;
    },
    setCurrentCalendarParams: (
      state,
      action: PayloadAction<ICurrentCalendarParams>
    ) => {
      state.currentCalendarParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase( // totalTimeLogged cuclulation
      getMemberTimeEntries.fulfilled,
      (
        state,
        action: PayloadAction<{
          timeEntries: ITimeEntryData[];
          params: ICurrentCalendarParams;
        }>
      ) => {
        const calendarStart = action.payload.params.start;
        const calendarEnd = action.payload.params.end;
        const selectedTimezone = action.payload.params.timeZoneName;

        const timeRanges = action.payload.timeEntries.map((timeEntry) => {
          return {
            start: dateToTimeZone(timeEntry.start, selectedTimezone),
            end: dateToTimeZone(timeEntry.end, selectedTimezone),
          };
        });

        state.totalTimeLogged = sumTimeRanges(
          timeRanges,
          calendarStart,
          calendarEnd
        );
      }
    );
    builder.addCase(getMemberTimeEntries.pending, (state) => {
      state.isLoading = true;
    });
    
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const {
  setInitialCalendarView,
  setInitialCalendarDate,
  setCurrentCalendarParams 
} = calendarInfoSlice.actions;

export const isLoadingCalendarInfoSelector = createSelector(
  [(state: RootState) => state.calendarInfo.isLoading],
  (isLoading) => isLoading
);

export const initialCalendarInfoViewSelector = createSelector(
  [(state: RootState) => state.calendarInfo.initialCalendarView],
  (view) => view
);

export const initialCalendarInfoDateSelector = createSelector(
  [(state: RootState) => state.calendarInfo.initialCalendarDate],
  (view) => view
);

export const totalTimeLoggedCalendarInfoSelector = createSelector(
  [(state: RootState) => state.calendarInfo.totalTimeLogged],
  (time) => time
);

export const calendarEventsSelector = createSelector(
  [
    timeEntriesSelector, holidaysListSelector, timeOffRequestsListSelector,
    (state: RootState, memberTimeZone: string) => memberTimeZone,
  ],
  (timeEntries, holidays, timeOffs, memberTimeZone) => {
    const events: FullCalendarEvent[] = [];

    timeEntries.forEach((timeEntry) => {
      events.push({
        id: `${EventTypes.TimeEntry}: ${timeEntry.id}`,
        title: `${timeEntry.projectName}`,
        start: formatISO(dateToTimeZone(timeEntry.start, memberTimeZone)),
        end: formatISO(dateToTimeZone(timeEntry.end, memberTimeZone)),
        borderColor: 'rgb(0, 122, 255)',
        backgroundColor: 'rgba(0, 122, 255, 0.05)',
      });
    });

    holidays.forEach((holiday) => {
      events.push({
        id: `${EventTypes.Holiday}: ${holiday.id}`, 
        title: `${EventTypes.Holiday}: ${holiday.name}`,
        start: formatISO(dateToTimeZone(holiday.date, "UTC")),
        end: formatISO(dateToTimeZone(holiday.date, "UTC")),
        borderColor: 'rgb(230, 46, 123)',
        backgroundColor: 'rgba(230, 46, 123, 0.05)',
      });
    });

    timeOffs.forEach((timeOff) => {
      events.push({
        id: `${EventTypes.Policy}: ${timeOff.id}`, 
        title: `${EventTypes.Policy}: ${timeOff.policy.name}`,
        start: formatISO(dateToTimeZone(timeOff.startTime, "UTC")),
        end: formatISO(dateToTimeZone(timeOff.endDate, "UTC")),
        borderColor: 'rgb(230, 46, 123)',
        backgroundColor: 'rgba(230, 46, 123, 0.05)',
      });
    });

    return events;
  }
);

export const currentCalendarParamsSelector = createSelector(
  [(state: RootState) => state.calendarInfo.currentCalendarParams],
  (params) => params
);
